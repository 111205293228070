import React from 'react';
import {ReactComponent as Svg2 }  from './2.svg'
import {ReactComponent as Svg3 }  from './3.svg'
import {ReactComponent as Svg4 }  from './4.svg'
import {ReactComponent as Svg5 }  from './5.svg'
import {ReactComponent as Svg6 }  from './6.svg'
import {ReactComponent as Svg7 }  from './7.svg'
import {ReactComponent as Svg8 }  from './8.svg'
import {ReactComponent as Svg9 }  from './9.svg'
import {ReactComponent as Svg10 }  from './10.svg'
import {ReactComponent as Svgace }  from './ace.svg'
import {ReactComponent as Svgjack }  from './jack.svg'
import {ReactComponent as Svgqueen }  from './queen.svg'
import {ReactComponent as Svgking }  from './king.svg'
import {ReactComponent as Svgjoker }  from './joker.svg'
import {ReactComponent as Svgclubs }  from './clubs.svg'
import {ReactComponent as Svgdiamonds }  from './diamonds.svg'
import {ReactComponent as Svghearts }  from './hearts.svg'
import {ReactComponent as Svgspades }  from './spades.svg'
import {ReactComponent as SvgjokerIcon }  from './joker_icon.svg'
import {ReactComponent as SvgqueenIcon }  from './queen_icon.svg'
import {ReactComponent as SvgkingIcon }  from './king_icon.svg'
import {ReactComponent as SvgjackIcon }  from './jack_icon.svg'

const icons = {
	2: <Svg2 />,
	3: <Svg3 />,
	4: <Svg4 />,
	5: <Svg5 />,
	6: <Svg6 />,
	7: <Svg7 />,
	8: <Svg8 />,
	9: <Svg9 />,
	10: <Svg10 />,
	ace: <Svgace />,
	queen: <Svgqueen />,
	king: <Svgking />,
	jack: <Svgjack />,
	joker: <Svgjoker />,
	clubs: <Svgclubs />,
	diamonds: <Svgdiamonds />,
	hearts: <Svghearts />,
	spades: <Svgspades />,
	joker_icon: <SvgjokerIcon />,
	queen_icon: <SvgqueenIcon />,
	king_icon: <SvgkingIcon />,
	jack_icon: <SvgjackIcon />
}

export default icons