import React, { useState, useEffect } from 'react';
import { CardType } from '../logic/cardList';
import CardInsignia from './CardInsignia'
import { useSpring, animated } from 'react-spring'


const Card = ({cardInfo, flipped}: {cardInfo: CardType, flipped?: boolean}) => {

const flipppedAnimation = {
	transform: `rotateY(180deg)`
}
const unflippedAnimation = {
	transform: `rotateY(0deg)`
}

	const animationProps = useSpring(flipped ? flipppedAnimation : unflippedAnimation)

	// Shows card number from cardList
	const { family, value, text } = cardInfo
	
	return <animated.div style={{transform: animationProps.transform}} className="cardWrapper">
	
		<FrontSideCard />
		<div className={`card card-back card-${family} card-${value} card-${family}-${value}`}	>
			<div className="card-inner">
				<CardInsignia 
						cardInfo={cardInfo}
						position={'top'}
					/>
				<span className="text">{text}</span>
				{ value === 'joker' && <div className="editable text" contentEditable={true}>
					

				</div>
				}
				<CardInsignia 
					cardInfo={cardInfo}
					position={'bottom'}
				/>
			</div>
	</div>
	</animated.div>
}

export const FrontSideCard = () => {
	return <div className={`card card-front`}>
			<div>
			<div className="text">
			<span className="knekk">Knekk</span>
				<span className="knekk">	inkluderings-</span>
				<span className="knekk">	koden</span>
				</div>
				<img className="fnlogo" src="./FN_Logo_White.png" width="30%" height="auto" />

			</div>
		</div>
}

export default Card