import cardList, { CardType } from './cardList'

const defaultCardAmount = 5

const pullCards = (amount = defaultCardAmount) => {
	let localCardList = [...cardList] // makes a copy so we can remove chosen cards from list
	const pulledCards: CardType[] = []

	for (let index = 0; index < amount; index++) {
//		console.log('List length: ',localCardList.length)
		const cardToAdd = localCardList[Math.floor(Math.random() * localCardList.length)]
		pulledCards.push(cardToAdd) // Select the card
//		console.log('selected ', cardToAdd.family, cardToAdd.value)
		localCardList = localCardList.filter((cardInfo) => {
			if (cardInfo.family === cardToAdd.family && cardInfo.value === cardToAdd.value) { 
				return null
			} else {
				return cardInfo
			}
		})
//		console.log('New list length: ',localCardList.length)
	}
	return pulledCards
}

const storeCards = (pulledCards: CardType[]) => {
	// Store cards in localstorage
	localStorage.setItem('cardsPulled', JSON.stringify(pulledCards));
}

export const refreshCards = (amount = defaultCardAmount) => {
	// Pull new cards and store them
	// TODO: Check already pulled cards to make sure we don't pull the same again
	const newCards = pullCards(amount)
	storeCards(newCards)
	return newCards
}

const getCards = (amount = defaultCardAmount) => {
	// Get stored cards, or pull new ones if none are stored
	try {
		const storedCards = localStorage.getItem('cardsPulled')
		if (storedCards) {
			const oldCards = JSON.parse(storedCards)
			return oldCards
		}
	} catch (error) {
		// No problem, just the JSON parse that failed
	}

	return refreshCards(amount)
	
}

export default getCards