import React from 'react'
import { CardType, cardFamilyType, cardValueType } from '../logic/cardList'
import icons from '../icons' 


const FamilyIcon = ({family}: {family: cardFamilyType}) => {
	switch (family) {
		case 'joker':
			return null
		default: 
			return  <>
			{icons[family]}
		</>
	}
}

const ValueIcon = ({value}: {value: cardValueType}) => {
	return <>
		{icons[value]}
	</>
}

const CardIcon = ({cardInfo }: {cardInfo: CardType}) => {
	const { value, family } = cardInfo
	switch (value) {
		case 'queen':
		case 'joker':
		case 'king':
		case 'jack':
			const index = value + "_icon" as 'queen_icon' | 'joker_icon' | 'king_icon' | 'jack_icon'
			return <span className="icon">	
					{icons[index]}
			</span>
		case 'ace': 
			return 	<span className="icon">	
				{icons[family]}
			</span>
		default:
			return null
	}
}

const CardInsignia = ({cardInfo, position = "top"}: {cardInfo: CardType, position: 'top' | 'bottom'}) => {
	const { family, value } = cardInfo

	return 	<div className={`card-insignia card-insignia--${position}`}>
		<div className="corner">
			<span className="value"><ValueIcon value={value}/></span>
			<span className="family"><FamilyIcon family={family} /></span>
		</div>
		<CardIcon cardInfo={cardInfo} />
	</div>
}

export default CardInsignia