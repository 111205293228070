import React, {useState } from 'react';
import Card from './Card'
import cardList from '../logic/cardList'

const AllCards = () => {
	const [showAllCards, setShowAllCards] = useState<boolean>(false)
	
	// Shows all cards
	return <>
	        <button onClick={() => {
						setShowAllCards(!showAllCards)
						if (!showAllCards) {
							document.getElementById('allCards')?.scrollIntoView({
								behavior: 'smooth'
							})
						} 
					}}>
          { !showAllCards ? 'Vis alle kortene' : 'Skjul X' }
        </button>
        <div id="allCards" className={showAllCards ? '' : 'hidden'}>
          <h2>Alle kortene:</h2>
					<ul 
						className="cardList cardList--all">
		{cardList.map((cardInfo, index) => {
			return <li className={`cardList-listItem`} key={`all-card-${index}`}>
					<Card cardInfo={cardInfo} flipped={false} />
				</li>
		})}
	</ul>
	</div>
        </> 
	
}

export default AllCards