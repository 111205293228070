import React, {useState, useMemo, useEffect} from 'react';
import Card from './Card'
import cardList, { CardType } from '../logic/cardList'
import getCards, { refreshCards } from '../logic/pullAndStoreCards';

function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
const PulledCards = ({amount = 5 }) => {
	const [pulledCards, setPulledCards] = useState<CardType[]>(getCards(amount))
	const [flipped, setFlipped] = useState(Array.from(Array(amount)).map(_i => true))
	const [flipping, setFlipping] = useState(false)

	const flipDelay = 100
	const newFlipArray = (index: number, value: boolean) => Array.from(Array(index + 1)).map(_i => value).concat(Array.from(Array(amount - (index + 1))).map(_i => !value))
	const newCardArray = (index: number, newCards: CardType[], oldCards: CardType[]) =>  newCards.slice(0, index + 1).concat(oldCards.slice(index + 1, newCards.length))

	const setCardsAndFlipAll = async (newCards: CardType[]) => {
		const oldCards = pulledCards
		setFlipping(true)
		await Promise.all(newCards.map(async (_newCard, index) => {
			await sleep(100 * index)
			setFlipped(newFlipArray(index, true)) // Flip the card
			await sleep(1000) // Wait for it to flip
			// Change the card
			setPulledCards(newCardArray(index, newCards, oldCards))
			await sleep(100)
			// Flip it back
			return setFlipped(newFlipArray(index, false)) // Flip the card
		}))
		setFlipping(false)
	}

	useEffect(() => {
		// Flip the cards after a few ms on load:
		const loadFlip = async (value: boolean) => {
			await sleep(1000) // 500ms until we start
			for (let index = 0; index < flipped.length; index++) {
				await sleep(100)
				setFlipped(newFlipArray(index, value))
			}
		}
		loadFlip(false)
		return () => {
			setFlipped(Array.from(Array(amount)).map(_i => true))
		}
	}, [])

	console.log(pulledCards.length)

	return <>
		<ul className="cardList">
			{
				// @ts-ignore
				pulledCards.map((cardInfo, index) =>
					<li className={`cardList-listItem`} key={index}>
							<Card flipped={flipped[index]} cardInfo={cardInfo} />
					</li>
				)
			}
		</ul>
	<p>
	<button className="pullNewCards" disabled={flipping} onClick={async () => {
		const newCards = refreshCards(amount)
		// Set the new cards
		setCardsAndFlipAll(newCards)
	}}>Trekk andre kort</button>
	</p>
	</>
}

export default PulledCards