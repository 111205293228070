
export type cardFamilyType = 'clubs' | 'diamonds' | 'hearts' | 'spades' | 'joker'
export type cardValueType =  'ace' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | 'jack' | 'queen' | 'king' | 'joker'
export interface CardType {
	family: cardFamilyType
	value: cardValueType
	text: string
}


const JokerStatement = "Inkludering er å …"

const cardFamilies: [
	'hearts',
	'diamonds',
	'clubs',
	'spades'
] = [
	'hearts',
	'diamonds',
	'clubs',
	'spades'
]

const values: [
	'ace',
	'king', 
	'queen', 
	'jack', 
	'10', 
	'9', 
	'8', 
	'7', 
	'6', 
	'5', 
	'4', 
	'3', 
	'2'
] = [
	'ace',
	'king', 
	'queen', 
	'jack', 
	'10', 
	'9', 
	'8', 
	'7', 
	'6', 
	'5', 
	'4', 
	'3', 
	'2'
]

const statements = [
	"Inkludering er å utfordre organisasjonens rekrutteringsvaner.",
	"Inkludering er å spørre seg selv om organisasjonen er mangfoldig.",
	"Inkludering er å invitere noen med på aktiviteter.",
	"Inkludering er å snakke sammen.",
	"Inkludering er å gi et smil til en ukjent.",
	"Inkludering er å ha mangfold som mål i organisasjonens planer. ",
	"Inkludering er å spørre noen som står alene om de vil være med på leken.",
	"Inkludering er å sette av nødvendige ressurser til å jobbe med mangfold.",
	"Inkludering er å ha informasjon som viser at vi vil inkludere og være mangfolde.",
	"Inkludering er å få med alle. Ikke bry seg om hvordan man ser ut.",
	"Inkludering er å ikke se på forskjeller som ulemper eller hindringer, men som styrker.",
	"Inkludering er å ta med andre enn din venn på kino.",
	"Inkludering er å ikke tenke at jeg er bedre enn deg.",
	"Inkludering er å la alle bidra med sine ressurser, på tross av ulikhet.",
	"Inkludering er å lære noe sammen.",
	"Inkludering er å skape noe sammen.",
	"Inkludering er å ha samme rettigheter og muligheter.",
	"Inkludering er å bli kjent med hverandre.",
	"Inkludering er å invitere folk til å være med på turer i nærområdet.",
	"Inkludering er å få folk til å føle seg velkommen, ønsket og verdsatt.",
	"Inkludering er å tilrettelegge for forskjeller og ulike behov.",
	"Inkludering er å prate med nye naboer og invitere dem til å ta del i det du gjør.",
	"Inkludering er å tørre å spørre.",
	"Inkludering er å ikke gi opp selv om ting ikke alltid går som forventet.",
	"Inkludering er å få alle til å føle seg like verdsatt og inkludert i fellesskapet.",
	"Inkludering er å ha tid til hverandre.",
	"Inkludering er å finne noe man kan gjøre raskt for å øke mangfoldet i organisasjonen.",
	"Inkludering er å lage fellesskap på tvers av kulturelle, etniske, sosiale og religiøse forskjeller.",
	"Inkludering er å gi mye og tydelige informasjon om ting som kan virke selvfølgelig.",
	"Inkludering er å sette av tid på møtene til å ta vare på nye medlemmer.",
	"Inkludering er å la de som er nye bruke tiden de trenger for å bli trygge.",
	"Inkludering er å være nysgjerrig på andre og deres kultur.",
	"Inkludering er å vise andre at man er interessert i nye deltagere og deres historie.",
	"Inkludering er at man kan føle seg hjemme igjen.",
	"Inkludering er å ha aktiviter som passer for alle.",
	"Inkludering er å sette nye medlemmer til å gjøre ting, så de føler seg velkommen og til nytte.",
	"Inkludering er å lage mat sammen og samles rundt bordet med tradisjonsmat fra ulike kulturer.",
	"Inkludering er å unngå at økonomi hindrer deltagelse.",
	"Inkludering er å unngå at språk blir en barriere, men finne andre måter å kommunisere på.",
	"Inkludering er å spørre de man ønsker å tilrettelegge for, hva som skal til for at de kan delta.",
	"Inkludering er å se de man samarbeider med som likeverdige partnere.",
	"Inkludering er å prøve noe nytt og å være villig til å endre organisasjonen.",
	"Inkludering er å spørre om noen trenger å få sitte på til neste møte eller fotballkamp.",
	"Inkludering er å bygge tillit mellom organisasjonen og foreldrene til barna som deltar.",
	"Inkludering er å fortelle nye innbyggere om hvordan samfunnet fungerer.",
	"Inkludering er å dele kunnskap om hvordan man driver en organisasjon.",
	"Inkludering er å samarbeide og bli kjent på tvers av ulike organisasjoner.",
	"Inkludering er å lage en rekrutteringsstrategi som skal øke mangfoldet.",
	"Inkludering er å konkretisere hvordan man skaper en inkluderende organisasjonskultur.",
	"Inkludering er å ha en plan for hvordan man tar i mot nye deltagere og medlemmer.",
	"Inkludering er å jobbe for mangfold blant ansatte og tillitsvalgte.",
	"Inkludering er å gjøre mangfoldsarbeidet til en del av organisasjonens ordinære arbeid.",
]

const CardGenerator = (): CardType[] => {
	const CardList: CardType[] = []
	
	cardFamilies.forEach((family, familyIndex) => {
		values.forEach((value, valueIndex) => {
			CardList.push({
				family,
				value,
				text: statements[familyIndex * values.length + valueIndex]
			})
		})
	})
	
	CardList.push({
		family: 'joker',
		value: 'joker',
		text: JokerStatement
	})
	CardList.push({
		family: 'joker',
		value: 'joker',
		text: JokerStatement
	})
	CardList.push({
		family: 'joker',
		value: 'joker',
		text: JokerStatement
	})

	return CardList

}

export default CardGenerator()
