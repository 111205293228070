import React from 'react';
import PullCards from './display/PulledCards'
import './App.css';
import AllCards from './display/AllCards';



function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>
          Dine fem kort:
        </h1>
        <nav id="menu">
        <a href="https://inkluderingskoden.no/inkluderingskortstokk">Om kortstokken</a>
        </nav>
      </header>
      <main>
        <PullCards />
      </main>
      <aside>
        <AllCards />
      </aside>
    </div>
  );
}

export default App;
